
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listProfileFeatures } from "@/graphql/queries";
import { updateProfileFeature } from "@/graphql/mutations";
import { createProfileFeature } from "@/core/database/data/profile/profile-graphql-mutations-operations.js";
import slugify from "slugify";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "account",
  setup() {
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    var userId = ref("");
    var isPhoneVerified = ref(false);
    var user = ref();

    Auth.currentAuthenticatedUser().then((user) => {
      console.log(user);
      user.value = user;
      userId.value = user.username;
      isPhoneVerified.value = user.attributes.phone_number_verified;
    });

    // const saveChanges = () => {
    //   if (submitButton.value) {
    //     // Activate indicator
    //     submitButton.value.setAttribute("data-kt-indicator", "on");
    //     setTimeout(() => {
    //       submitButton.value?.removeAttribute("data-kt-indicator");
    //       // router.push({ name: "overview" });
    //     }, 2000);
    //   }
    // };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Conta", "Seus dados");
    });

    return {
      user,
      isPhoneVerified,
      // saveChanges,
      submitButton,
    };
  },
  mounted() {
    this.fullscreenLoading = true;
    setTimeout(() => {
      this.loadProfile();
      this.fullscreenLoading = false;
    }, 3000);
  },
  data() {
    return {
      fullscreenLoading: false,
      profile: {
        id: "",
        username: "",
        avatar: "",
        name: "",
        birthday: "",
        age: 0,
        gender: "",
        verified: false,
        geolocation: null,
        metadata: "",
        enabled: true,
        auth_signup_id: null,
      },
      accountVerification: {
        phone_number: "",
        email: "",
      },
      address: {
        cep: "",
        street: "",
        number: "",
        city: "",
        state: "",
        country: "",
      },
    };
  },
  methods: {
    formattedDate() {
      let formattedDate = "";
      if (this.profile.birthday) {
        const dateObject = new Date(this.profile.birthday);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // +1 pois os meses são indexados de 0 a 11
        const day = String(dateObject.getDate()).padStart(2, "0");

        formattedDate = `${year}-${month}-${day}`;
      }
      return formattedDate;
    },
    async submitForm() {
      if (this.profile.id) {
        this.updateProfile();
      } else {
        this.createProfile();
      }
    },
    async createProfile() {
      try {
        Auth.currentAuthenticatedUser().then((user) => {
          let data = {
            username: this.profile.username,
            avatar: null,
            name: this.profile.name,
            birthday: this.formattedDate(),
            age: 0,
            gender: this.profile.gender,
            geolocation: null,
            verified: true,
            metadata: JSON.stringify({
              auth_signup_id: user.username,
              phone_number: this.accountVerification.phone_number,
              email: this.accountVerification.email,
              business_collection: [],
              address: this.address,
            }),
            auth_signup_id: user.username,
            enabled: true,
          };

          createProfileFeature(data).then((response) => {
            this.buildProfile(response);
          });

          Swal.fire({
            text: "Conta atualizada",
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        });
      } catch (error) {
        console.error("Error creating profile:", error);
        Swal.fire({
          text: "Por favor, revise seus dados e tente novamente.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, vou revisar!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-warning",
          },
        });
      }
    },
    async updateProfile() {
      try {
        this.profile.birthday = this.formattedDate();
        this.profile.metadata = JSON.stringify({
          phone_number: this.accountVerification.phone_number,
          email: this.accountVerification.email,
          address: this.address,
        });

        delete this.profile["createdAt"];
        delete this.profile["updatedAt"];
        delete this.profile["_deleted"];
        delete this.profile["_lastChangedAt"];
        delete this.profile["__typename"];

        const response: any = await API.graphql(
          graphqlOperation(updateProfileFeature, { input: this.profile })
        );

        this.buildProfile(response.data.updateProfileFeature);

        Swal.fire({
          text: "Conta atualizada",
          icon: "info",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      } catch (error) {
        console.error("Error updating data:", error);
        Swal.fire({
          text: "Por favor, revise seus dados e tente novamente.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, vou revisar!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-warning",
          },
        });
      }
    },
    async loadProfile() {
      try {
        var username = null;
        await Auth.currentAuthenticatedUser().then((user) => {
          username = user.username;
        });

        if (username) {
          const response: any = await API.graphql(
            graphqlOperation(listProfileFeatures, {
              filter: {
                auth_signup_id: {
                  eq: username,
                },
                enabled: {
                  eq: true,
                },
              },
            })
          );

          if (response.data.listProfileFeatures.items.length > 0) {
            this.buildProfile(response.data.listProfileFeatures.items[0]);
          } else {
            Auth.currentAuthenticatedUser().then((user) => {
              this.profile.username = slugify(
                user.attributes.name,
                "."
              ).toLowerCase();
              this.profile.name = user.attributes.name;
              this.profile.auth_signup_id = user.username;
              this.accountVerification = {
                phone_number: user.attributes.phone_number,
                email: user.attributes.email,
              };
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    buildProfile(profile) {
      this.profile = profile;
      if (this.profile) {
        let metadata = JSON.parse(this.profile.metadata);
        this.profile.metadata = metadata;
        this.address = metadata.address ? metadata.address : this.address;
        this.accountVerification = {
          phone_number: metadata.phone_number,
          email: metadata.email,
        };
      }
    },
  },
});
